<template>
  <v-dialog
    v-model="dialogEnviadosOsCaixa"
    @click:outside="fechaAtualiza"
    @keydown.esc="fechaAtualiza"
    width="1200px"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-tabs grow v-model="tab">
          <v-tab>
            {{ $tc("global.ordensServicos", 2) }}
            {{ $tc("global.pendentes", 2) }}</v-tab
          >
          <v-tab>
            {{ $tc("global.ordensServicos", 2) }}
            {{ $tc("global.finalizada", 2) }}
          </v-tab>
        </v-tabs>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-6 py-8">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <ListaOs v-if="tab === 0" />
          </v-tab-item>

          <v-tab-item>
            <ListaOsFinalizadas v-if="tab === 1" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogEnviadosOsCaixa",
  components: {
    ListaOs: () => import("./ListaOs.vue"),
    ListaOsFinalizadas: () => import("./ListaOsFinalizadas.vue"),
  },
  props: {
    dialogEnviadosOsCaixa: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      tab: 0,
    };
  },
  methods: {
    fechaAtualiza() {
      this.$emit("update:dialogEnviadosOsCaixa", false);
      this.$emit("fetch-caixa-mov");
    },
  },
  computed: {},
  watch: {},
  mounted() {},
};
</script>

<style>
</style>